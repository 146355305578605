import { client } from './api-client'

interface CreateAccountPayload {
    name: string;
    email: string;
    password: string;
    phone: string;
}

interface VerifyOTPpayload {
    code: string;
    phone: string;
}

interface LoginData {
    phone: string;
    // password: string;  
}

function AccountLogin(payload: LoginData | undefined) {
    return client('kedco-payment/api/auth', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))
}

function CreateNewAccount(payload: CreateAccountPayload) {
    return client('kedco-payment/api/signup/init', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))
}

function VerifySignupOTP(payload: VerifyOTPpayload) {
    return client('kedco-payment/api/signup', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))
}

function InitResetPassword(payload: {phone: string}) {
    return client('kedco-payment/api/auth/reset', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))
}

function CompleteResetPass(payload: {code: string; password: string;}) {
    return client('kedco-payment/api/auth/password', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))    
}

function GetUserProfile(payload: {profile: number}) {
    return client('kedco-payment/api/auth/refresh', {body: payload})
        .then(response => {
            if(response.meta.status !== '200') {
                return Promise.reject(response)
            }

            return Promise.resolve(response)
        }, e => Promise.reject({meta: {message: 'Please check your internet connection'}}))        
}

export {
    CreateNewAccount,
    VerifySignupOTP,
    AccountLogin,
    InitResetPassword,
    CompleteResetPass,
    GetUserProfile
}