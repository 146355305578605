import React from 'react';

interface LoaderProps {classNames: string; style?: React.CSSProperties}

interface DotLoderProps {
    size: number;
    classNames?: string;
    dots: {
        size: number;
        color: string;
    }
}

function Loader({classNames, style}: LoaderProps) {
    return (
        <div className={`spin-loader ${classNames}`} style={{...style}}></div>
    )
}

export function DotsLoader(props: DotLoderProps) {
    return (
        <div className={`dots w-${props.size} h-${props.size} ${props.classNames}`}>
          <div className={`w-${props.dots.size} h-${props.dots.size} ${props.dots.color}`}></div>
          <div className={`w-${props.dots.size} h-${props.dots.size} ${props.dots.color}`}></div>
          <div className={`w-${props.dots.size} h-${props.dots.size} ${props.dots.color}`}></div>
        </div>
    )
}

export default Loader