import React from "react";
import { navigate } from "@reach/router";
import { GetUserProfile } from "../utils/auth-client";

interface User {
  profile: number;
  name: String;
  phone: string;
  lastdate: string;
  lasttime: string;
  email: string;
  meters: Array<{
    account_type: "prepaid" | "postpaid";
    account_name: string;
    account_number: string;
    meter_number: string;
  }>;
}

interface AuthContextState {
    user: User | undefined;
    logout: () => void;
    SetUser: (data: any) => void;
}

const AuthContext = React.createContext<AuthContextState>({
  user: undefined,
  logout: () => null,
  SetUser: (data: any) => null
})

const AuthProvider = (props: any) => {
    const [user, setUser] = React.useState<User | undefined>(undefined);

    React.useLayoutEffect(() => {
      const userParams = window.localStorage.getItem("@kedco_user_params")
      if(userParams) {
        const loggedInUser = JSON.parse(userParams)
        setUser(loggedInUser)
        GetUserProfile({profile: loggedInUser.profile}).then(response => {
          SetUser(response.data)
        })
        .catch(e => logout())
      }
    }, [setUser])

    // SetUser data to context and persist in localStorage
    const SetUser = (data: User) => {
      setUser(data)
      window.localStorage.setItem("@kedco_user_params", JSON.stringify(data))
    }

    // Remove user data from localStorage
    // navigate to home, and reload window to logout
    const logout = () => {
      setUser(undefined)
      window.localStorage.removeItem("@kedco_user_params")
      navigate('/sign-in')
      window.location.reload()
    }

    return (
      <AuthContext.Provider value={{user, logout, SetUser}}>
          {props.children}
      </AuthContext.Provider>
    )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}