import React from 'react';
import './assets/css/index.css';
import { useAuth } from './context/auth-context';
import Loader from './components/Loader';

const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

const App = () => {
  const AuthContext = useAuth()
  const user = AuthContext?.user;
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])
  
  return (
    <React.Suspense fallback={
      <div className="w-full absolute h-full flex items-center justify-center"> 
        <Loader classNames="primary-spinner w-12 h-12" /> 
      </div>
    }>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export default App;
